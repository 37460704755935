import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardBody,
  Media, CardHeader, Collapse, Table, Input,
} from "reactstrap";
import classnames from "classnames";
import SectionTitle from "../../components/Shared/SectionTitle";
import {withTranslation} from "react-i18next";
import AvtoYullariService from "../../api/AvtoYullariService";

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {

      items:[],
      activeTab: "1",
      col1: true,
      col2: false,
      col3: false,
      col4: false,
      pathItems: [
        //id must required
        { id: 1, name: "Landrick", link: "/index" },
        { id: 2, name: "Shop", link: "#" },
        { id: 3, name: "Cart" },
      ],

    };
    this.toggle = this.toggle.bind(this);
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }
  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
    });
  }
  speak=(event)=>{
    if(window.isSpeakingEnabled)
    {
      this._speech.text =event.currentTarget.textContent;
      this._speech.lang = 'ru-RU'
      window.speechSynthesis.speak(this._speech);}
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
    });
  }
  t_col3() {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
    });
  }
  t_col4() {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
    });
  }
  componentDidMount() {
    this._speech = new SpeechSynthesisUtterance();
    AvtoYullariService.getAll().then((response)=>{
      this.setState({
        items:response.data
      })
    })

  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div style={{maxWidth:"1450px", margin:"0 auto"}}>
            {/* section title */}
            <SectionTitle
              title={this.props.t('schedule')}
            />
            <Row className="mt-4 pt-2 justify-content-center">
              <Col lg={8} md={12} className="text-center">
                <Nav
                  pills
                  className="rounded nav-justified flex-column flex-sm-row"
                >
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: this.state.activeTab === "1" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <div
                        className="text-center pt-1 pb-1"
                        style={{ minWidth: "200px" }}
                      >
                        <h6 onClick={this.speak} className="title font-weight-normal mb-0">
                          {this.props.t('xalqaroAY')}
                        </h6>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: this.state.activeTab === "2" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      <div
                        className="text-center pt-1 pb-1"
                        style={{ minWidth: "200px" }}
                      >
                        <h6 onClick={this.speak}className="title font-weight-normal mb-0">
                          {this.props.t('davlatAY')}
                        </h6>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: this.state.activeTab === "3" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      <div
                        className="text-center pt-1 pb-1"
                        style={{ minWidth: "200px" }}
                      >
                        <h6 onClick={this.speak}className="title font-weight-normal mb-0">
                          {this.props.t('mahalliyAY')}
                        </h6>
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className="tab-content" id="pills-tabContent">
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">

                      {this.state.items.map((item, key) =>{
                        if(item.yulturi==='Халкаро')
                          return(
                      <Card className="border-0 rounded mb-2">
                        <Link
                            to="#"
                            onClick={this.t_col1}
                            className={
                              this.state.col1
                                  ? "faq position-relative text-primary"
                                  : "faq position-relative text-dark"
                            }
                        >
                          <CardHeader
                              className="border-0 shadow bg-light p-3"
                              id="headingOne"
                          >
                            <h6 className="title mb-0" onClick={this.speak}>
                              {" "}
                              {item.yulnomi}
                              <i
                                  className={
                                    this.state.col1
                                        ? "mdi mdi-chevron-up float-right"
                                        : "mdi mdi-chevron-down float-right"
                                  }
                              ></i>
                            </h6>
                          </CardHeader>
                        </Link>
                        <Collapse isOpen={this.state.col1}>
                          <CardBody>
                            <Row>
                              <Col xs={12}>
                                <div >
                                  <Table  >
                                    <thead style={{fontSize:"16px"}} >
                                    <tr style={{textAlign:"center"}}>
                                      <th onClick={this.speak}style={{padding:"0.41rem"}}>
                                        {this.props.t('yolU')}

                                        <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}>KM-KM</th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}>KM</th>
                                        </tr>

                                      </th>
                                      <th style={{padding:"0.41rem"}}>
                                        {this.props.t('qoplamaT')}

                                        <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('sB')}</th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}>{this.props.t('aB')}</th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('qora')}</th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('shagal')}</th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('tuproq')}</th>
                                        </tr>

                                      </th>
                                      <th style={{padding:"0.41rem"}}>
                                        {this.props.t('turkumi')}

                                        <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >I</th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}>II</th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >III</th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >IV</th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >V</th>
                                        </tr>

                                      </th >
                                      <th style={{padding:"0.41rem"}}>
                                        {this.props.t('kopriklar')}

                                        <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('jami')}

                                            <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                            </tr>

                                          </th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}}>{this.props.t('temirB')}

                                            <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                            </tr>
                                            </th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('temir')}

                                            <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                            </tr>
                                           </th>

                                        </tr>

                                      </th>
                                      <th onClick={this.speak} style={{padding:"0.41rem"}}>
                                        {this.props.t('quvurlar')}

                                        <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('jami')}

                                            <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                            </tr>

                                          </th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}}>{this.props.t('temirB')}

                                            <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                            </tr>
                                            </th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('temir')}

                                            <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                            </tr>
                                            </th>
                                          <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('asbS')}

                                            <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center",padding:"0.41rem"}}>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                              <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                            </tr>
                                            </th>

                                        </tr>

                                      </th>

                                    </tr>
                                    </thead>

                                    <tbody style={{fontSize:"15px"}} >

                                        <tr key={key} style={{textAlign:"center"}}>
                                          <td style={{padding:"0.41rem"}}>
                                            <tr style={{width:"100%",float:"left",display:"flex"}}>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}> {item.kmdan+'-'}{item.kmgacha}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}> {item.yuluzunligi}</td>
                                            </tr>

                                          </td>
                                          <td style={{padding:"0.41rem"}}>
                                            <tr style={{width:"100%",float:"left",display:"flex"}}>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamasb}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamaab}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamaqq}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamash}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamatq}</td>


                                            </tr>

                                          </td>
                                          <td style={{padding:"0.41rem"}}>
                                            <tr style={{width:"100%",float:"left",display:"flex"}}>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa1}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa2}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa3}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa4}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa5}</td>


                                            </tr>

                                          </td>
                                          <td style={{padding:"0.41rem"}}>
                                            <tr style={{width:"100%",float:"left",display:"flex"}}>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kuprikjamidona}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kuprikjamipm}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktbdona}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktbpm}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktrdona}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktrpm}</td>


                                            </tr>

                                          </td>
                                          <td style={{padding:"0.41rem"}}>
                                            <tr style={{width:"100%",float:"left",display:"flex"}}>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurjamidona}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurjamipm}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtbdona}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtbpm}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrdona}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrpm}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrasbdona}</td>
                                              <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrasbpm}</td>


                                            </tr>

                                          </td >
                                        </tr>

                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Collapse>
                      </Card>)})}
                    </TabPane>
                    <TabPane tabId="2">
                      {this.state.items.map((item, key) =>{
                        if(item.yulturi==='Давлат')
                          return(
                              <Card className="border-0 rounded mb-2">
                                <Link
                                    to="#"
                                    onClick={this.t_col1}
                                    className={
                                      this.state.col1
                                          ? "faq position-relative text-primary"
                                          : "faq position-relative text-dark"
                                    }
                                >
                                  <CardHeader
                                      className="border-0 shadow bg-light p-3"
                                      id="headingOne"
                                  >
                                    <h6 onClick={this.speak}className="title mb-0">
                                      {" "}
                                      {item.yulnomi}
                                      <i
                                          className={
                                            this.state.col1
                                                ? "mdi mdi-chevron-up float-right"
                                                : "mdi mdi-chevron-down float-right"
                                          }
                                      ></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col1}>
                                  <CardBody>
                                    <Row>
                                      <Col xs={12}>
                                        <div >
                                          <Table  >
                                            <thead style={{fontSize:"15px"}} >
                                            <tr style={{textAlign:"center"}}>
                                              <th onClick={this.speak}style={{padding:"0.41rem"}}>
                                                {this.props.t('yolU')}

                                                <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}>KM-KM</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}>KM</th>
                                                </tr>

                                              </th>
                                              <th onClick={this.speak}style={{padding:"0.41rem"}}>
                                                {this.props.t('qoplamaT')}

                                                <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('sB')}</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}>{this.props.t('aB')}</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('qora')}</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('shagal')}</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('tuproq')}</th>
                                                </tr>

                                              </th>
                                              <th onClick={this.speak}style={{padding:"0.41rem"}}>
                                                {this.props.t('turkumi')}

                                                <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >I</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}>II</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >III</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >IV</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >V</th>
                                                </tr>

                                              </th >
                                              <th onClick={this.speak}style={{padding:"0.41rem"}}>
                                                {this.props.t('kopriklar')}

                                                <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('jami')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>

                                                  </th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}}>{this.props.t('temirB')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>
                                                  </th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('temir')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>
                                                  </th>

                                                </tr>

                                              </th>
                                              <th onClick={this.speak}style={{padding:"0.41rem"}}>
                                                {this.props.t('quvurlar')}

                                                <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('jami')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>

                                                  </th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}}>{this.props.t('temirB')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>
                                                  </th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('temir')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>
                                                  </th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('asbS')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center",padding:"0.41rem"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>
                                                  </th>

                                                </tr>

                                              </th>

                                            </tr>
                                            </thead>

                                            <tbody style={{fontSize:"15px"}} >

                                            <tr key={key} style={{textAlign:"center"}}>
                                              <td style={{padding:"0.41rem"}}>
                                                <tr style={{width:"100%",float:"left",display:"flex"}}>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}> {item.kmdan+'-'}{item.kmgacha}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}> {item.yuluzunligi}</td>
                                                </tr>

                                              </td>
                                              <td style={{padding:"0.41rem"}}>
                                                <tr style={{width:"100%",float:"left",display:"flex"}}>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamasb}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamaab}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamaqq}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamash}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamatq}</td>


                                                </tr>

                                              </td>
                                              <td style={{padding:"0.41rem"}}>
                                                <tr style={{width:"100%",float:"left",display:"flex"}}>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa1}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa2}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa3}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa4}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa5}</td>


                                                </tr>

                                              </td>
                                              <td style={{padding:"0.41rem"}}>
                                                <tr style={{width:"100%",float:"left",display:"flex"}}>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kuprikjamidona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kuprikjamipm}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktbdona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktbpm}</td>
                                                  <td onClick={this.speak} style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktrdona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktrpm}</td>


                                                </tr>

                                              </td>
                                              <td style={{padding:"0.41rem"}}>
                                                <tr style={{width:"100%",float:"left",display:"flex"}}>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurjamidona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurjamipm}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtbdona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtbpm}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrdona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrpm}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrasbdona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrasbpm}</td>


                                                </tr>

                                              </td >
                                            </tr>

                                            </tbody>
                                          </Table>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Collapse>
                              </Card>)})}
                    </TabPane>
                    <TabPane tabId="3">
                      {this.state.items.map((item, key) =>{
                        if(item.yulturi==='Махаллий')
                          return(
                              <Card className="border-0 rounded mb-2">
                                <Link
                                    to="#"
                                    onClick={this.t_col1}
                                    className={
                                      this.state.col1
                                          ? "faq position-relative text-primary"
                                          : "faq position-relative text-dark"
                                    }
                                >
                                  <CardHeader
                                      className="border-0 shadow bg-light p-3"
                                      id="headingOne"
                                  >
                                    <h6 className="title mb-0">
                                      {" "}
                                      {item.yulnomi}
                                      <i
                                          className={
                                            this.state.col1
                                                ? "mdi mdi-chevron-up float-right"
                                                : "mdi mdi-chevron-down float-right"
                                          }
                                      ></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col1}>
                                  <CardBody>
                                    <Row>
                                      <Col xs={12}>
                                        <div >
                                          <Table  >
                                            <thead style={{fontSize:"16px"}} >
                                            <tr style={{textAlign:"center"}}>
                                              <th onClick={this.speak}style={{padding:"0.41rem"}}>
                                                {this.props.t('yolU')}

                                                <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}>KM-KM</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}>KM</th>
                                                </tr>

                                              </th>
                                              <th onClick={this.speak}style={{padding:"0.41rem"}}>
                                                {this.props.t('qoplamaT')}

                                                <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('sB')}</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}>{this.props.t('aB')}</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('qora')}</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('shagal')}</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >{this.props.t('tuproq')}</th>
                                                </tr>

                                              </th>
                                              <th onClick={this.speak}style={{padding:"0.41rem"}}>
                                                {this.props.t('turkumi')}

                                                <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >I</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}>II</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >III</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >IV</th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}} >V</th>
                                                </tr>

                                              </th >
                                              <th style={{padding:"0.41rem"}}>
                                                {this.props.t('kopriklar')}

                                                <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('jami')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>

                                                  </th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}}>{this.props.t('temirB')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>
                                                  </th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('temir')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>
                                                  </th>

                                                </tr>

                                              </th>
                                              <th style={{padding:"0.41rem"}}>
                                                {this.props.t('quvurlar')}

                                                <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('jami')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>

                                                  </th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}}>{this.props.t('temirB')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>
                                                  </th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('temir')}

                                                    <tr onClick={this.speak}style={{width:"100%",display:"flex",float:"left",textAlign:"center"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>
                                                  </th>
                                                  <th onClick={this.speak}style={{textAlign:"center",width:"33%",padding:"0.41rem"}} >{this.props.t('asbS')}

                                                    <tr style={{width:"100%",display:"flex",float:"left",textAlign:"center",padding:"0.41rem"}}>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}} >{this.props.t('dona')}</th>
                                                      <th onClick={this.speak}style={{textAlign:"center",padding:"0.41rem"}}>{this.props.t('pM')}</th>

                                                    </tr>
                                                  </th>

                                                </tr>

                                              </th>

                                            </tr>
                                            </thead>

                                            <tbody style={{fontSize:"15px"}} >

                                            <tr key={key} style={{textAlign:"center"}}>
                                              <td style={{padding:"0.41rem"}}>
                                                <tr style={{width:"100%",float:"left",display:"flex"}}>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}> {item.kmdan+'-'}{item.kmgacha}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"50%",padding:"0.41rem"}}> {item.yuluzunligi}</td>
                                                </tr>

                                              </td>
                                              <td style={{padding:"0.41rem"}}>
                                                <tr style={{width:"100%",float:"left",display:"flex"}}>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamasb}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamaab}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamaqq}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamash}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.qoplamatq}</td>


                                                </tr>

                                              </td>
                                              <td style={{padding:"0.41rem"}}>
                                                <tr style={{width:"100%",float:"left",display:"flex"}}>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa1}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa2}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa3}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa4}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.toifa5}</td>


                                                </tr>

                                              </td>
                                              <td style={{padding:"0.41rem"}}>
                                                <tr style={{width:"100%",float:"left",display:"flex"}}>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kuprikjamidona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kuprikjamipm}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktbdona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktbpm}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktrdona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.kupriktrpm}</td>


                                                </tr>

                                              </td>
                                              <td style={{padding:"0.41rem"}}>
                                                <tr style={{width:"100%",float:"left",display:"flex"}}>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurjamidona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurjamipm}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtbdona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtbpm}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrdona}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrpm}</td>
                                                  <td onClick={this.speak}style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrasbdona}</td>
                                                  <td onClick={this.speak} style={{textAlign:"center",width:"20%",padding:"0.41rem"}}> {item.quvurtrasbpm}</td>


                                                </tr>

                                              </td >
                                            </tr>

                                            </tbody>
                                          </Table>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Collapse>
                              </Card>)})}
                    </TabPane>
                  </TabContent>
                </div>
              </Col>

            </Row>

          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Schedule);
